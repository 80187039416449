import { graphql, StaticQuery } from "gatsby";
import React, { useState } from "react";
import DarkLayout from "../components/dark-layout";

export const siteGROQ = `
*[_id == $id] {
  _id,
  _type,
  title,
  darkLogo {
    alt,
    asset->
  },
  headerNavigation[] {
    label,
    target
  },
  footerNavigation[] {
    label,
    description,
    target
  },
  socialHeader,
  socialLinks[] {
    icon {
      alt,
      asset->
    },
    target
  },
  footerText
}`;

const query = graphql`
  query DarkSiteTitleQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      darkLogo {
        asset {
          _id
        }
        alt
      }

      headerNavigation {
        label
        target
      }
      footerNavigation {
        label
        description
        target
      }
      socialHeader
      socialLinks {
        icon {
          asset {
            _id
          }
          alt
        }
        target
      }
      _rawFooterText
    }
  }
`;

function DarkLayoutContainer(props) {
  const [showNav, setShowNav] = useState(false);
  function handleShowNav() {
    setShowNav(true);
  }
  function handleHideNav() {
    setShowNav(false);
  }
  return (
    <StaticQuery
      query={query}
      render={(data) => {
        if (!data.site) {
          throw new Error(
            'Missing "Site settings". Open the studio at http://localhost:3333 and add "Site settings" data'
          );
        }
        return (
          <DarkLayout
            {...props}
            showNav={showNav}
            siteTitle={data.site.title}
            siteLogo={data.site.darkLogo}
            headerNavigation={data.site.headerNavigation}
            footerNavigation={data.site.footerNavigation}
            socialHeader={data.site.socialHeader}
            socialLinks={data.site.socialLinks}
            footerText={data.site._rawFooterText}
            onHideNav={handleHideNav}
            onShowNav={handleShowNav}
          />
        );
      }}
    />
  );
}

export default DarkLayoutContainer;
