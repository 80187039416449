import React from "react";
import DarkLayout from "../containers/darkLayout";

export default function test() {
  return (
    <DarkLayout>
      <div style={{ minHeight: "70vh" }}>
        <h1>hello</h1>
      </div>
    </DarkLayout>
  );
}
