import React, { useState } from "react";
import { Link } from "gatsby";
import DarkHeader from "./dark-header";
import { imageUrlFor } from "../lib/image-url";
import { buildImageObj } from "../lib/helpers";
import BaseBlockContent from "@sanity/block-content-to-react";
import CookieConsent from "react-cookie-consent";
import Popup from "./cookie-consent/popup";

import "../styles/layout.css";
import styles from "./layout.module.css";

const DarkLayout = ({
  children,
  companyInfo,
  onHideNav,
  onShowNav,
  showNav,
  siteTitle,
  siteLogo,
  headerNavigation,
  footerNavigation,
  socialHeader,
  socialLinks,
  footerText,
}) => {
  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  return (
    <div
      id="page"
      style={{
        position: "relative",
        zIndex: 1,
        minHeight: "100vh",
        backgroundColor: "#211f20",
      }}
      className={showNav ? "site open" : "site closed"}
    >
      {showPopup ? <Popup closePopup={togglePopup} /> : null}
      <div id="opacityBackground" />
      <DarkHeader
        siteTitle={siteTitle}
        siteLogo={siteLogo}
        headerNavigation={headerNavigation}
        onHideNav={onHideNav}
        onShowNav={onShowNav}
        showNav={showNav}
      />
      <div className={styles.darkContent}>{children}</div>

      <footer id="footer" role="contentinfo">
        <div className="fullWidthBlack clear">
          <div className="wp1220">
            {footerNavigation &&
              footerNavigation.map((footerNav, i) => (
                <section
                  key={i}
                  id="custom_html-3"
                  className="widget_text widget widget_custom_html"
                >
                  <div className="textwidget custom-html-widget">
                    <div className="col1_3">
                      <h2>
                        <Link to={footerNav.target}>
                          <span className="border">&nbsp;</span>
                          {footerNav.label}
                          <span className="arrow"></span>
                        </Link>
                      </h2>
                      <span className="padTop">{footerNav.description}</span>
                    </div>
                  </div>
                </section>
              ))}
            <section
              id="custom_html-2"
              className="widget_text widget widget_custom_html"
            >
              <div className="textwidget custom-html-widget">
                <div className="col1_3">
                  <h2>{socialHeader}</h2>
                  {socialLinks &&
                    socialLinks.map((socialLink, i) => (
                      <a className="last" key={i} href={socialLink.target}>
                        <img
                          src={imageUrlFor(buildImageObj(socialLink.icon))
                            .width(30)
                            //.height(Math.floor((9 / 16) * 600))
                            .url()}
                          alt={socialLink.icon.alt}
                          width={10}
                        />
                      </a>
                    ))}
                </div>
              </div>
            </section>
          </div>
          <div id="rights">
            {footerText && (
              <span>
                <BaseBlockContent blocks={footerText} />
              </span>
            )}
          </div>
        </div>
      </footer>
      <CookieConsent
        buttonText="Accept"
        cookieName="AlephCookie"
        disableStyles={true}
        style={{
          maxWidth: 300,
          width: "100%",
          height: 140,
          background: "rgba(35, 31, 32, 1)",
          padding: 20,
          position: "fixed",
          bottom: 0,
        }}
        contentStyle={{
          fontSize: 11,
          color: "#fff",
          margin: 0,
        }}
        buttonStyle={{
          color: "white",
          background: "none",
          border: "1px solid white",
          padding: "5px 20px",
          float: "right",
          marginTop: 5,
          alignSelf: "flex-end",
        }}
        expires={150}
      >
        This site uses cookies to personalize your experience, analyze site
        usage, and offer tailored promotions.{" "}
        <a
          style={{ color: "#fff", textDecoration: "underline" }}
          href="/"
          onClick={() => togglePopup()}
        >
          more information
        </a>
      </CookieConsent>
    </div>
  );
};

export default DarkLayout;
